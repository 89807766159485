import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/Home";
import SuccessfulProcess from "@/components/SuccessfulProcess";
import NotFound from "@/components/NotFound";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/successful-process",
      name: "successful-process",
      component: SuccessfulProcess
    },
    {
      path: "/*",
      name: "not-found",
      component: NotFound
    }
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    return { x: 0, y: 0 };
  }
});

export default router;
