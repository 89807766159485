<template>
  <div>
    <div v-if="is_url_expiration == false && url_signed == false">
      <div class="flex justify-center" v-if="loading">
        <propagate-loader
          class="spinner"
          color="#FF561E"
          :loading="loading"
          :size="50"
        ></propagate-loader>
      </div>
      <!-- PARTNER DATA SECCION INIT -->
      <div class="flex flex-wrap">
        <div class="headers">
          <h2>Datos del Socio</h2>
        </div>
        <div class="partner-col" v-for="data in partner_data" :key="data.label">
          <p class="text-orangePrimary">{{ data.label }}</p>
          <p class="font-semibold">{{ data.value }}</p>
        </div>
      </div>
      <!-- ADDRESS DATA SECCION INIT -->
      <div class="flex flex-wrap">
        <div class="headers">
          <h2>Datos del Domicilio</h2>
        </div>
        <div class="address-col" v-for="data in address_data" :key="data.label">
          <p class="text-orangePrimary">{{ data.label }}</p>
          <p class="font-semibold">{{ data.value }}</p>
        </div>
      </div>
      <!-- COVERAGE DATA SECCION INIT -->
      <div v-if="selected_products.length > 0">
        <div class="headers">
          <h2>Tipo de Cobertura</h2>
        </div>
        <div class="hideInSmallScreens">
          <div class="flex flex-wrap">
            <div
              class="benefits-col md:ml-5"
              v-if="this.selected_products != null"
            >
              <p class="text-orangePrimary">Cobertura</p>
              <p
                class="benefits-col w-full md:mb-6"
                v-for="data in selected_products"
                :key="data.product_id"
              >
                <span
                  class="mr-2 text-orangePrimary font-bold text-xl align-middle"
                  >{{ data.product_id }}</span
                >
                {{ data.product_name }}
              </p>
            </div>
            <div class="benefits-col inline-flex">
              <div>
                <p class="text-orangePrimary"></p>
                <div
                  class="mt-6"
                  v-for="data in selected_products"
                  :key="data.product_id"
                >
                  <p class="text-dark font-semibold ml-5">Plan A</p>
                  <p class="text-dark font-semibold ml-5">Plan B</p>
                </div>
              </div>
              <div>
                <p class="text-orangePrimary">Módulos</p>
                <div
                  class=" "
                  v-for="data in selected_products"
                  :key="data.product_id"
                >
                  <p class="text-dark font-semibold ml-5">
                    {{ data.plan_a }}
                  </p>
                  <p class="text-dark font-semibold ml-5 mb-6">
                    {{ data.plan_b }}
                  </p>
                </div>
              </div>
            </div>

            <div class="benefits-col md:w-2/12 text-right">
              <p class="text-orangePrimary">Suma Qnal.</p>
              <div
                class="text-dark font-semibold"
                v-for="data in selected_products"
                :key="data.product_id"
              >
                <p>
                  {{
                    (data.unit_cost_plan_a_prorated + data.admin_cost_plan_a)
                      | toCurrency
                  }}
                </p>
                <p class="mb-6">
                  {{
                    (data.unit_cost_plan_b_prorated + data.admin_cost_plan_b)
                      | toCurrency
                  }}
                </p>
              </div>
            </div>
            <div class="benefits-col text-right -pl-10">
              <p class="text-orangePrimary">Suma Asegurada</p>
              <p
                class="text-dark font-semibold mb-12 mt-2"
                v-for="data in selected_products"
                :key="data.product_id"
              >
                {{ data.sum_assured | toCurrency }}
              </p>
            </div>
            <div class="w-full">
              <div class="grid grid-cols-4">
                <div class="md:col-start-3 m-5">
                  <hr class="bg-orangePrimary" />
                </div>
                <div class="md:col-start-4 m-5">
                  <hr class="bg-orangePrimary" />
                </div>
              </div>
              <div class="grid md:grid-cols-4 text-center mb-5">
                <div class="md:col-start-2">
                  <p class="text-orangePrimary text-right">Subtotal</p>
                </div>
                <div class="md:col-start-3 font-semibold">
                  <p>{{ sumQnalTotal | toCurrency }}</p>
                </div>
                <div class="md:col-start-4 font-semibold">
                  <p>{{ sumAssuredTotal | toCurrency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Movil-->
        <div class="showInSmallScreens">
          <div class="flex flex-wrap">
            <div
              class="benefits-col"
              v-for="(data, index) in selected_products"
              :key="data.product_id"
            >
              <div class="benefits-col w-full">
                <p class="text-orangePrimary">Cobertura</p>
                <p class="">
                  <span
                    class="mr-2 text-orangePrimary font-bold text-xl align-middle"
                    >{{ index + 1 }}</span
                  >{{ data.product_name }}
                </p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Plan</p>
                <p>Plan A</p>
                <p>Plan B</p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Módulos</p>
                <div>
                  <p class="text-dark font-semibold">
                    {{ data.plan_a }}
                  </p>
                  <p class="text-dark font-semibold">
                    {{ data.plan_b }}
                  </p>
                </div>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Prima Qnal.</p>
                <p class="text-dark font-semibold">
                  {{
                    (data.unit_cost_plan_a_prorated + data.admin_cost_plan_a)
                      | toCurrency
                  }}
                </p>
                <p class="text-dark font-semibold">
                  {{
                    (data.unit_cost_plan_b_prorated + data.admin_cost_plan_b)
                      | toCurrency
                  }}
                </p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Suma Asegurada</p>
                <p class="text-dark font-semibold">
                  {{ data.total_cost | toCurrency }}
                </p>
              </div>
            </div>
            <div class="w-full text-center text-xs text-orangePrimary">
              <p>Subtotal</p>
            </div>
            <div class="benefits-col text-xs pl-6 pr-6">
              <hr class="" />
            </div>
            <div class="benefits-col text-xs pl-6 pr-6">
              <hr class="" />
            </div>
            <div class="benefits-col text-xs font-semibold text-center">
              <p>{{ sumQnalTotal | toCurrency }}</p>
            </div>
            <div class="benefits-col text-xs font-semibold text-center">
              <p>{{ sumAssuredTotal | toCurrency }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- BENEFICIARIES DATA SECCION INIT -->
      <div class="flex flex-wrap" v-if="beneficiaries.length > 0">
        <div class="headers">
          <h2>Beneficiarios del Asegurado Titular</h2>
        </div>
        <div class="beneficiaries-col md:w-3/12 md:text-left">
          <p class="text-primary text-orangePrimary">Nombres</p>
          <p
            class="font-semibold"
            v-for="data in beneficiaries"
            :key="data.client_id"
          >
            {{
              data.first_name +
              " " +
              data.second_name +
              " " +
              data.paternal_lastname +
              " " +
              data.maternal_lastname
            }}
          </p>
        </div>
        <div class="beneficiaries-col">
          <p class="text-primary text-orangePrimary">Parentesco</p>
          <p
            class="font-semibold"
            v-for="data in beneficiaries"
            :key="data.client_id"
          >
            {{ data.kinship }}
          </p>
        </div>
        <div class="beneficiaries-col">
          <p class="text-primary text-orangePrimary">Porcentaje(%)</p>
          <p
            class="font-semibold"
            v-for="data in beneficiaries"
            :key="data.client_id"
          >
            {{ data.percentage }}
          </p>
        </div>
      </div>
      <!-- ADDITIONAL INSURED DATA SECCION INIT -->
      <div v-if="additionals_assured.length > 0">
        <div class="headers">
          <h2>Asegurados Adicionales</h2>
        </div>
        <div class="hideInSmallScreens">
          <div class="flex flex-wrap">
            <div class="additionals-col md:w-3/12 md:text-left md:ml-8">
              <p class="text-primary text-orangePrimary text-left">Nombres</p>
              <p
                class="font-semibold text"
                v-for="data in additionals_assured"
                :key="data.first_name"
              >
                {{
                  data.first_name +
                  " " +
                  data.second_name +
                  " " +
                  data.paternal_lastname +
                  " " +
                  data.maternal_lastname
                }}
              </p>
            </div>
            <div class="additionals-col">
              <p class="text-primary text-orangePrimary">Fecha Nac.</p>
              <p
                class="font-semibold"
                v-for="data in additionals_assured"
                :key="data.first_name"
              >
                {{ data.date_birth }}
              </p>
            </div>
            <div class="additionals-col">
              <p class="text-primary text-orangePrimary">Género</p>
              <p
                class="font-semibold ml-5"
                v-for="data in additionals_assured"
                :key="data.first_name"
              >
                {{ data.gender }}
              </p>
            </div>
            <div class="additionals-col md:w-2/12 md:ml-5">
              <p class="text-orangePrimary">Cobertura</p>
              <p
                class="font-semibold md:mb-12"
                v-for="data in selected_products_additionals"
                :key="data.product_id"
              >
                {{ data.product_name }}
              </p>
            </div>
            <div class="additionals-col inline-flex">
              <div>
                <p class="text-orangePrimary"></p>
                <div
                  class="md:mt-6"
                  v-for="data in selected_products_additionals"
                  :key="data.product_id"
                >
                  <p class="text-dark font-semibold ml-5">Plan A</p>
                  <p class="text-dark font-semibold ml-5">Plan B</p>
                </div>
              </div>
              <div>
                <p class="text-orangePrimary">Módulos</p>
                <div
                  class="md:mb-6"
                  v-for="data in selected_products_additionals"
                  :key="data.product_id"
                >
                  <p class="text-dark font-semibold ml-5">
                    {{ data.plan_a }}
                  </p>
                  <p class="text-dark font-semibold ml-5">
                    {{ data.plan_b }}
                  </p>
                </div>
              </div>
            </div>

            <div class="additionals-col md:w-2/12 text-center">
              <p class="text-orangePrimary">Suma Qnal.</p>
              <div
                class="text-dark font-semibold"
                v-for="data in selected_products_additionals"
                :key="data.product_id"
              >
                <p>
                  {{
                    (data.unit_cost_plan_a_prorated + data.admin_cost_plan_a)
                      | toCurrency
                  }}
                </p>
                <p class="md:mb-6">
                  {{
                    (data.unit_cost_plan_b_prorated + data.admin_cost_plan_b)
                      | toCurrency
                  }}
                </p>
              </div>
            </div>
            <div class="additionals-col md:w-1/12 text-right">
              <p class="text-orangePrimary">Suma Asegurada</p>
              <p
                class="text-dark font-semibold md:mb-12"
                v-for="data in selected_products_additionals"
                :key="data.product_id"
              >
                {{ data.sum_assured | toCurrency }}
              </p>
            </div>
            <div class="w-full">
              <div class="grid grid-cols-7">
                <div class="md:col-start-6 m-5">
                  <hr class="bg-orangePrimary" />
                </div>
                <div class="md:col-start-7 m-5">
                  <hr class="bg-orangePrimary" />
                </div>
              </div>
              <div class="grid md:grid-cols-7 text-center mb-5">
                <div class="md:col-start-5">
                  <p class="text-orangePrimary text-right">Subtotal</p>
                </div>
                <div class="md:col-start-6 font-semibold">
                  <p>
                    {{ sumQnalTotalAdditionals | toCurrency }}
                  </p>
                </div>
                <div class="md:col-start-7 font-semibold">
                  <p>{{ sumAssuredTotalAdditionals | toCurrency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Movil-->
        <div class="showInSmallScreens">
          <div class="flex flex-wrap">
            <div
              class="benefits-col"
              v-for="(data, index) in selected_products_additionals"
              :key="data.product_id"
            >
              <div class="benefits-col w-full">
                <p class="text-orangePrimary">Cobertura</p>
                <p class="">
                  <span
                    class="mr-2 text-orangePrimary font-bold text-xl align-middle"
                    >{{ index + 1 }}</span
                  >{{ data.product_name }}
                </p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Plan</p>
                <p>Plan A</p>
                <p>Plan B</p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Módulos</p>
                <div>
                  <p class="text-dark font-semibold">
                    {{ data.plan_a }}
                  </p>
                  <p class="text-dark font-semibold">
                    {{ data.plan_b }}
                  </p>
                </div>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Prima Qnal.</p>
                <p class="text-dark font-semibold">
                  {{
                    data.plan_a > 0
                      ? data.unit_cost_plan_a * data.plan_a
                      : 0 | toCurrency
                  }}
                </p>
                <p class="text-dark font-semibold">
                  {{
                    data.plan_b > 0
                      ? data.unit_cost_plan_b * data.plan_b
                      : 0 | toCurrency
                  }}
                </p>
              </div>
              <div class="benefits-col text-center text-xs">
                <p class="text-orangePrimary">Suma Asegurada</p>
                <p class="text-dark font-semibold">
                  {{ data.total_cost | toCurrency }}
                </p>
              </div>
            </div>
            <div class="w-full text-center text-xs text-orangePrimary">
              <p>Subtotal</p>
            </div>
            <div class="benefits-col text-xs pl-6 pr-6">
              <hr class="" />
            </div>
            <div class="benefits-col text-xs pl-6 pr-6">
              <hr class="" />
            </div>
            <div class="benefits-col text-xs font-semibold text-center">
              <p>{{ sumQnalTotalAdditionals | toCurrency }}</p>
            </div>
            <div class="benefits-col text-xs font-semibold text-center">
              <p>{{ sumAssuredTotalAdditionals | toCurrency }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- DISCOUNT AUTHORIZATION DATA SECCION INIT -->
      <div class="flex flex-wrap">
        <div class="headers">
          <h2>Autorizo de Descuento</h2>
        </div>
        <div class="m-5">
          <p class="text-orangePrimary">Estatus Laboral</p>
          <p class="text-dark font-semibold">{{ workplace.status }}</p>
        </div>
        <div class="m-5">
          <p class="text-orangePrimary">Base</p>
          <p class="text-dark font-semibold">{{ workplace.base }}</p>
        </div>
      </div>
      <div class="w-full">
        <hr class="m-10" />
        <div class="mt-10 px-10 lg:px-24">
          <p class="text-xl text-justify">
            Por este conducto autorizo que la cantidad
            {{ totalSumQnal | toCurrency }} sea descontada de mis sueldo durante
            XX pagos o hasta cubrir la totalidad de mis seguros. Para cualquier
            aclaración o reclamo se utilizarán los comprobantes de sueldo (talón
            de cheque) los cuales presentaré en el momento requerido. Así mismo
            estoy de acuerdo en que sí por cualquier circunstancia no se me
            descuenta la cantidad pactada, ésta sea aplicada en las quincenas
            subsecuentes. Acepto que se proceda a la cancelación de los
            beneficios adquiridos si, por cualquier motivo, no se me puede
            aplicar descuento o no realizo los pagos correspondientes por 3
            quincenas consecutivas o bien si por descuentos incompletos acumulo
            un adeudo por un momento equivalente a 3 quincenas de aportación, y
            no regularizo esta situación en las oficinas de Línea Magisterial.
            Hago constar que estoy enterado(a) y de acuerdo con cada uno de los
            puntos anteriores por lo que firmo de conformidad en uso de los
            facultades mentales. Los valores autorizados en este documento son
            fijos por el periodo de un año a partir de la fecha de contratación
            de los seguros y autorizo las variaciones a la alza o a la baja para
            las correspondientes renovaciones automáticas , que podrán ser con
            la compañía de seguros autorizada o con otra compañía de seguros que
            no afecte las coberturas contratadas, Autorizo se verifique la
            liquidez a través del talón de cheque o de medios electrónicos. Si
            el asegurado desea no continuar con la protección debe acudir a las
            oficinas de Línea Magisterial para trámite de cancelación o
            solicitarlo a través de nuestros promotores. Las aportaciones para
            maestros con licencia se harán directamente en las oficinas de Línea
            Magisterial.
          </p>
        </div>
        <div class="text-center mt-10 mb-10 px-10 items-center">
          <span>He leído y acepto las condiciones del </span
          ><a
            class="text-goodGreen"
            href=""
            v-on:click.prevent="getPrivacynotice()"
            >Aviso de Privacidad</a
          >
        </div>
        <div class="text-center mb-5">
          <button
            v-bind:class="[
              'hover:bg-darkGray text-xl py-1 px-20 m-2 rounded-full',
              acceptConditions ? 'activeClass' : 'bg-lightGray',
            ]"
            v-on:click="
              (notice_of_privacy_type =
                'plan_costos_v200501_recibi_reglamento_si'),
                (acceptConditions = true)
            "
          >
            Sí
          </button>
          <button
            v-bind:class="[
              'hover:bg-darkGray text-xl py-1 px-20 m-2 rounded-full',
              acceptConditions === false ? 'activeClass' : 'bg-lightGray',
            ]"
            v-on:click="
              (notice_of_privacy_type =
                'plan_costos_v200501_recibi_reglamento_no'),
                (acceptConditions = false)
            "
          >
            No
          </button>
        </div>
        <div class="text-center">
          <p class="text-orangePrimary text-2xl font-semibold">Firma Digital</p>
          <p class="px-5">Realice su firma como en su identificación oficial</p>
        </div>
        <div class="w-full mt-5">
          <div class="flex flex-col lg:flex-row justify-center">
            <div class="w-full flex justify-center text-brown">
              <VueSignaturePad
                id="signature"
                width="45%"
                height="150px"
                ref="signaturePad"
                class="border-solid"
              />
            </div>
          </div>

          <div class="text-right flex flex-row">
            <div class="w-1/3"></div>
            <div class="w-1/3">
              <button
                class="mt-5 bg-lightGray hover:bg-lightGray text-xl py-1 px-20 rounded-full"
                @click="undo"
              >
                Deshacer
              </button>
            </div>
            <div class="w-1/3"></div>
          </div>
        </div>

        <div class="text-center px-5" v-if="acceptConditions == false">
          <span class="text-red-600 text-xl font-semibold"
            >Debes aceptar las condiciones del Aviso de Privacidad para
            continuar</span
          >
        </div>

        <div class="text-center px-5" v-if="showMsgSign == true">
          <span class="text-red-600 text-xl font-semibold"
            >Ingrese la firma</span
          >
        </div>

        <div class="text-center px-5" v-if="document_signed == true">
          <span class="text-red-600 text-xl font-semibold"
            >El documento ya ha sido firmado</span
          >
        </div>
        <div
          class="flex justify-center w-full mt-10 mb-5"
          v-if="loading_finish"
        >
          <propagate-loader
            class="w-full flex justify-center spinner"
            color="#FF561E"
            :loading_finish="loading_finish"
            :size="50"
          ></propagate-loader>
        </div>
        <div class="text-center mt-10 mb-5">
          <button
            @click="goToPDF"
            class="bg-normalYellow hover:bg-hoverYellow text-white text-xl py-1 px-20 rounded-full"
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="is_url_expiration == true || url_signed == true">
      <div class="bg-darkBlue mt-2 lg:mt-5">
        <span
          class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl"
          >{{ errors }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import jsonData from "../assets/data.json";
import Vue from "vue";
Vue.filter("toCurrency", function (value) {
  /*if (typeof value !== "number") {
    return value;
  }*/
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(parseFloat(value));
});
export default {
  name: "Home",
  data() {
    return {
      jsonData: jsonData,
      client: [],
      partner_data: [],
      address_data: [],
      selected_products: [],
      beneficiaries: [],
      additionals_assured: [],
      selected_products_additionals: [],
      workplace: [],
      name: null,
      cellphone_number: null,
      sumAssuredTotal: 0,
      sumQnalTotal: 0,
      sumQnalTotalAdditionals: 0,
      sumAssuredTotalAdditionals: 0,
      totalSumQnal: 0,
      signed_document: false,
      email: "",
      email_associate: "",
      url_path_pdf_autorizo: null,
      url_path_pdf_plan_a: null,
      url_path_pdf_plan_b: null,
      url_pdf_autorizo: null,
      url_pdf_plan_a: null,
      url_pdf_plan_b: null,

      apiCabsaCore: "https://www.cabsacore.com/",
      //apiCabsaCore: "http://localhost:3000/",
      //apiCabsaCore: "http://200.92.206.58/",
      token_auth_valid: "",
      loading_finish: false,
      show_finish: true,
      is_previous_letter: false,
      is_new_letter: false,
      is_url_expiration: null,
      url_is_expiration: false,
      errors: null,
      url_response_json: null,
      url_response_pdf: null,
      url_path_response_json: null,
      url_path_response_pdf: null,
      name_catalog_document: "plan_costos_v200501_firma",
      paths_pdf_signed: [],
      notice_of_privacy_type: "",
      is_not_document_signed: true,
      urlParams: null,
      autorizo: null,
      plan_a: null,
      plan_b: null,
      json_file: null,
      data: null,
      url_for_download_pdf: [],
      document_signed: false,
      loading: false,
      acceptConditions: null,
      url_signed: false, //null
      options: {
        penColor: "#1F2933",
      },
      showMsgSign: false,
      section: null,
      name_catalog: "",
      name_catalog_signature: "",
      name_catalog_notice_of_privacy: "",
    };
  },
  async beforeMount() {
    this.urlParams = new URLSearchParams(window.location.search);
    this.autorizo = this.urlParams.get("autorizo"); //autorizo
    this.plan_a = this.urlParams.get("plan_a"); //a o b
    this.plan_b = this.urlParams.get("plan_b"); //b
    this.json_file = this.urlParams.get("json_file");
    await this.postLogin();
    await this.postUrlExpiration();
    await this.validateStatusUrl();
    if (this.url_is_expiration == false && this.url_signed == false) {
      this.getJsonInformation();
      this.getPdfInformation();
      if (this.plan_a != null) {
        this.getPdfInformation2();
      }
      if (this.plan_b != null) {
        this.getPdfInformation3();
      }
    }
    this.loading = false;
  },
  methods: {
    async postLogin() {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        usuario_api: {
          email: "firma_autorizo@fomapo.com", //"f.pena@gcabsa.com.mx", //"firma_autorizo@fomapo.com", //fas_test@gcabsa.com.mx
          password: "cabsa2020", //"*cabsa2018*" //"cabsa2020"
        },
      };

      await this.axios
        .post(this.apiCabsaCore + "login", data, config)
        .then((dataa) => {
          this.token_auth_valid = dataa.data.response.token_auth;
          localStorage.setItem("token_auth_valid", this.token_auth_valid);
        });
    },
    async postUrlExpiration() {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.loading = true;
      await this.axios
        .post(
          this.apiCabsaCore + "uploader/expiry_time",
          {
            token_auth: this.token_auth_valid,

            
            url: window.location.toString(),
             // "https://firma.autorizo.lineamagisterial.uno/?autorizo=88289b8a8d46c5d71f5dba65b2edb15b.pdf&plan_b=a6fa7c51fa76e1aa19c4f369d9002473.pdf&json_file=60c00157550cf251651f08cf3ccf014e.json",
            business_acronym: "LMAYE",
          },
          config
        )
        .then((response) => {
          console.log("verifique que url no este expirada");
          if (response.data.errors.length == 0) {
            this.url_is_expiration = false;
            return true;
          }
        })
        .catch((error) => {
          error.response.data.errors;
          this.errors =
            "El documento que desea firmar ya ha vencido, favor de contactar a su asesor.";
          this.url_is_expiration = true;
          this.is_url_expiration = true;
          console.log("url expirada", this.errors);
          return false;
        });
    },
    async validateStatusUrl() {
      this.loading = true;
      const params = {
        token_auth: this.token_auth_valid,

        
        url: window.location.toString(),
          //"https://firma.autorizo.lineamagisterial.uno/?autorizo=88289b8a8d46c5d71f5dba65b2edb15b.pdf&plan_b=a6fa7c51fa76e1aa19c4f369d9002473.pdf&json_file=60c00157550cf251651f08cf3ccf014e.json",
        business_acronym: "LMAYE",
      };
      await this.axios
        .get(this.apiCabsaCore + "get_status_digital_signature", {
          params,
        })
        .then((response) => {
          if (response.data.url_status == "FIRMADO") {
            this.url_signed = true;
            this.errors =
              "El documento que desea firmar ya ha sido firmado, favor de contactar a su asesor.";
            console.log("error: ", this.errors);
            console.log("validate URL:", response.data.url_status);
            console.log("url_status:", this.url_signed);
          } else {
            this.url_signed = false;
          }
        })
        .catch((error) => {
          console.log("Error: ", error.response.data.errors);
          this.errors =
            "Se presentó un error al validar el estatus de la url, favor de contactar a su asesor.";
        });
    },
    async getJsonInformation() {
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file_name: this.json_file,
        business_acronym: "LMAYE",
        process: "policy_and_discount_auth_json",
      };
      await this.axios
        .post(this.apiCabsaCore + "uploader/get_file_cloud", data, config)
        .then((response) => {
          if (response.data.errores == "") {
            this.url_response_json = response.data.url; // aqui se encuentra el json
            this.getJsonInformationFromUrl();
            this.is_url_expiration = false;
          } else {
            this.errors = response.data.errores;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errores;
          this.loading = false;
          console.log("Error en getJsonInformation: ", error);
        });
    },
    getJsonInformationFromUrl() {
      this.axios
        .get(this.url_response_json, "", "")
        .then((response) => {
          console.log("json: ", response.data);
          this.section = response.data.policy.section;
          console.log("section: ", this.section);
          this.client = response.data.policy.policy_request.client;
          //this.client = jsonData.policy.policy_request.client;
          this.name =
            this.client.first_name +
            " " +
            (this.client.second_name ? this.client.second_name : "") +
            " " +
            this.client.paternal_lastname +
            " " +
            this.client.maternal_lastname;
          this.cellphone_number =
            "(" +
            this.client.lada_cellphone +
            ") " +
            this.client.cellphone_number;
          this.partner_data = [
            { label: "R.F.C", value: this.client.rfc },
            { label: "Nombre Completo", value: this.name },
            { label: "Estado Civil", value: this.client.marital_status },
            { label: "Género", value: this.client.gender },
            { label: "Correo Electrónico", value: this.client.emails },
            { label: "Telefóno", value: this.cellphone_number },
          ];
          this.address_data = [
            { label: "Calle", value: this.client.street },
            { label: "Número Exterior", value: this.client.exterior_number },
            { label: "Número Interior", value: this.client.interior_number },
            { label: "Código Postal", value: this.client.zip_code },
            { label: "Colonia", value: this.client.neighborhood },
            { label: "Municipio", value: this.client.municipality },
            { label: "Estado", value: this.client.state },
            { label: "Ciudad", value: this.client.city },
          ];

          //selected_products titular
          if (this.client.selected_products.details != null) {
            this.selected_products = this.client.selected_products.details;
          }
          for (let i = 0; i < this.selected_products.length; i++) {
            this.sumAssuredTotal += Number(
              this.selected_products[i].sum_assured
            );
          }
          let sumQnal = 0;
          for (let i = 0; i < this.selected_products.length; i++) {
            sumQnal += Number(this.selected_products[i].total_cost);
          }
          this.sumQnalTotal = sumQnal;

          this.beneficiaries = this.client.beneficiaries;

          //additionals assured
          //selected_products addionals assured

          if (this.client.additionals_assured.length > 0) {
            this.additionals_assured = this.client.additionals_assured;
            this.selected_products_additionals = this.client.additionals_assured[0].selected_products.details;
            //sumAssuredTotal
            console.log("additionals_assured: ", this.additionals_assured);
            for (
              let i = 0;
              i < this.selected_products_additionals.length;
              i++
            ) {
              this.sumAssuredTotalAdditionals += Number(
                this.selected_products_additionals[i].sum_assured
              );
            }
            //sumAssuredQnal
            for (
              let i = 0;
              i < this.selected_products_additionals.length;
              i++
            ) {
              this.sumQnalTotalAdditionals += Number(
                this.selected_products_additionals[i].total_cost
              );
            }
          }
          console.log("additionals_assured:", this.additionals_assured.length);
          this.totalSumQnal = this.sumQnalTotalAdditionals + this.sumQnalTotal;
          this.workplace = this.client.workplace;
          //var sum = 0;
        })
        .catch((error) => {
          console.log("Error en getJsonInformationFromUrl: ", error);
        });
    },
    async getPdfInformation() {
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file_name: this.autorizo,
        business_acronym: "LMAYE",
        process: "policy_and_discount_auth_pdf",
      };
      await this.axios
        .post(this.apiCabsaCore + "uploader/get_file_cloud", data, config)
        .then((response) => {
          if (response.data.errores == "") {
            this.url_pdf_autorizo = response.data.url;
            this.url_path_pdf_autorizo = response.data.path;
          } else {
            this.errors = response.data.errores;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errores;
          this.loading = false;
          console.log("Error en getPdfInformation ", error);
        });
    },
    async getPdfInformation2() {
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        file_name: this.plan_a,
        business_acronym: "LMAYE",
        process: "policy_and_discount_auth_pdf",
      };
      await this.axios
        .post(this.apiCabsaCore + "uploader/get_file_cloud", data, config)
        .then((response) => {
          if (response.data.errores == "") {
            this.url_pdf_plan_a = response.data.url;
            this.url_path_pdf_plan_a = response.data.path;
          } else {
            this.errors = response.data.errores;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errores;
          this.loading = false;
        });
    },
    async getPdfInformation3() {
      if (this.plan_b != null) {
        this.errors = null;
        let config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let data = {
          token_auth: this.token_auth_valid,
          file_name: this.plan_b,
          business_acronym: "LMAYE",
          process: "policy_and_discount_auth_pdf",
        };
        await this.axios
          .post(this.apiCabsaCore + "uploader/get_file_cloud", data, config)
          .then((response) => {
            if (response.data.errores == "") {
              this.url_pdf_plan_b = response.data.url;
              this.url_path_pdf_plan_b = response.data.path;
            } else {
              this.errors = response.data.errores;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.errors = error.response.data.errores;
            this.loading = false;
          });
      }
    },
    encriptarMensaje() {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );
      //https://firma.autorizo.lineamagisterial.uno/img/logo-horizontal.9b86b702.png
      var data = {
        html:
          "<h2>Información del interesado</h2>" +
          "<b>Nombre:</b> " +
          this.name +
          "<br><b>Correo:</b> " +
          this.email +
          "<br><b>Celular: </b>" +
          this.cellphone_number +
          "<br><br><br>" +
          '<img src="https://firma.autorizo.lineamagisterial.uno/img/logo-horizontal.9b86b702.png" ' +
          'alt="Logo" width="137" heght="160" style="display:block; font-family:Arial; color:#000000; ' +
          'font-size:14px;" border="0" />',
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      return encodedHeader + "." + encodedPayload + "." + signature;
    },
    async goToPDF() {
      this.show_finish = false;
      this.loading_finish = true;
      this.save();
      if (this.acceptConditions == true) {
        if (this.wroteSign == true) {
          await this.insertDigitalSignatureToFile();
          await this.UploadFiles();
          if (
            this.is_not_document_signed == true &&
            this.url_for_download_pdf.length > 0
          ) {
            localStorage.removeItem("pdf_for_download");
            localStorage.setItem(
              "pdf_for_download",
              JSON.stringify(this.url_for_download_pdf)
            );
            await this.sendEmail();
            this.$router.push({ name: "successful-process" });
          }
          this.document_signed = true;
          this.showMsgSign = false;
        } else {
          console.log("firma vacia");
          this.document_signed = false;
          this.showMsgSign = true;
        }
      }
      this.acceptConditions = false;
      this.loading_finish = false;
      this.show_finish = true;
    },
    async UploadFiles() {
      console.log("paths_pdf_signed: ", this.paths_pdf_signed);
      for (let i = 0; i <= this.paths_pdf_signed.length - 1; i++) {
        if (i == this.paths_pdf_signed.length - 1) {
          await this.postUploadFile(this.paths_pdf_signed[i], true);
        } else {
          await this.postUploadFile(this.paths_pdf_signed[i], false);
        }
      }
    },
    undo() {
      //método para deshacer firma
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      //método para guardar la firma
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.wroteSign = false;
        return {
          isEmpty: isEmpty,
          data: data,
        };
      } else {
        this.wroteSign = true;
        return {
          isEmpty: isEmpty,
          data: data.split(",")[1],
        };
      }
    },
    change() {
      this.options = {
        penColor: "#1F2933",
      };
    },
    resume() {
      this.options = {
        penColor: "#1F2933",
      };
    },
    async insertDigitalSignatureToFile() {
      this.searchCatalog();
      var listDocuments = [
        {
          token_auth: this.token_auth_valid,
          path_file_to_sign: this.url_path_pdf_autorizo,
          name_catalog_document: this.name_catalog,
          name_catalog_document_signature: this.name_catalog_signature,
          name_catalog_document_notice_of_privacy: this
            .name_catalog_notice_of_privacy,
        },
      ];
      if (this.plan_a != null) {
        listDocuments.push({
          token_auth: this.token_auth_valid,
          path_file_to_sign: this.url_path_pdf_plan_a,
          name_catalog_document: "Plan_A",
          name_catalog_document_signature: "solicitud_AP_firma",
          name_catalog_document_notice_of_privacy: "reglamento_no_aplica",
        });
      }
      if (this.plan_b != null) {
        listDocuments.push({
          token_auth: this.token_auth_valid,
          path_file_to_sign: this.url_path_pdf_plan_b,
          name_catalog_document: "Plan_B",
          name_catalog_document_signature: "solicitud_AP_firma",
          name_catalog_document_notice_of_privacy: "reglamento_no_aplica",
        });
      }
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        business_acronym: "LMAYE",
        digital_signature: this.save().data,
        list_documents: listDocuments,
      };
      await this.axios
        .post(
          this.apiCabsaCore + "uploader/insert_digital_signature_to_files",
          data,
          config
        )
        .then((response) => {
          if (response.data.errores == "") {
            this.paths_pdf_signed = response.data.documents_signatures;
          } else {
            this.errors = true;
          }
          this.loading = false;
          console.log("Se firmo correctamente");
        })
        .catch((error) => {
          this.errors = error.response.data.errores;
          this.loading = false;
        });
    },
    searchCatalog() {
      switch (this.section) {
        case "Seccion 2":
          this.name_catalog = "autorizo_descuento_AP_seccion_2";
          this.name_catalog_signature = "autorizo_descuento_AP_seccion_2_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_2_reglamento";
          break;
        case "Seccion 24":
          this.name_catalog = "autorizo_descuento_AP_seccion_24";
          this.name_catalog_signature =
            "autorizo_descuento_AP_seccion_24_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_24_reglamento";
          break;
        case "Seccion 26":
          this.name_catalog = "autorizo_descuento_AP_seccion_26";
          this.name_catalog_signature =
            "autorizo_descuento_AP_seccion_26_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_26_reglamento";
          break;
        case "Seccion 28":
          this.name_catalog = "autorizo_descuento_AP_seccion_28";
          this.name_catalog_signature =
            "autorizo_descuento_AP_seccion_28_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_28_reglamento";
          break;
        case "Seccion 37":
          this.name_catalog = "autorizo_descuento_AP_seccion_37";
          this.name_catalog_signature =
            "autorizo_descuento_AP_seccion_37_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_37_reglamento";
          break;
        case "Seccion 54":
          this.name_catalog = "autorizo_descuento_AP_seccion_54";
          this.name_catalog_signature =
            "autorizo_descuento_AP_seccion_54_firma";
          this.name_catalog_notice_of_privacy =
            "autorizo_descuento_AP_seccion_54_reglamento";
          break;
        default:
          (this.name_catalog = "Autorizo"),
            (this.name_catalog_signature = "autorizo_descuento_AP_firma"),
            (this.name_catalog_notice_of_privacy =
              "autorizo_descuento_AP_reglamento_si");
      }
    },
    insertDatesToCompensation(compensation) {
      compensation = {
        0: {
          name: "Cáncer",
          aditional: "",
          protection: this.cancer_info.cancer_protection,
          date: this.cancer_info.cancer_acquisition_date,
          cost: this.cancer_info.cancer_contribution,
        },
        1: {
          name: "Enfermedades graves",
          aditional: "",
          protection: this.ge_info.ge_protection,
          date: this.ge_info.ge_acquisition_date,
          cost: this.ge_info.ge_contribution,
        },
      };
      return compensation;
    },
    async sendEmail() {
      var emailToSendFirst = "";
      var emailToSendSecond = "";
      this.email = this.client.emails;
      console.log("this.partner_data.emails: ", this.client.emails);
      if (this.email != "" && this.email_associate == "") {
        emailToSendFirst = this.email;
      }
      if (this.email == "" && this.email_associate != "") {
        emailToSendFirst = this.email_associate;
      }
      if (this.email != "" && this.email_associate != "") {
        emailToSendFirst = this.email;
        emailToSendSecond = this.email_associate;
      }
      console.log(
        "this.url_for_download_pdf SENDEMAIL",
        this.url_for_download_pdf
      );
      let emails = [];
      emails.push({ email: emailToSendFirst });
      if (emailToSendSecond != "") {
        emails.push({ email: emailToSendSecond });
      }
      let message = this.encriptarMensaje();
      var urls_to_send = [
        {
          file: this.url_for_download_pdf[0].url,
          name: this.url_for_download_pdf[0].document_name + ".pdf",
        },
        {
          file: this.url_for_download_pdf[1].url,
          name: this.url_for_download_pdf[1].document_name + ".pdf",
        },
      ];
      console.log("this.urls_to_send: ", this.urls_to_send);
      if (this.url_for_download_pdf.length > 2)
        urls_to_send.push({
          file: this.url_for_download_pdf[2].url,
          name: this.url_for_download_pdf[2].document_name + ".pdf",
        });
      this.axios
        .post("https://www.cabsacore.com/mailer/send_email", {
          mail: {
            business: "Linea Magisterial",
            process_type: "documentacion de ap",
            title: "Firma Digital AP",
            body_mail: message,
            //"eyJhbGciOiJIUzI1NiJ9.eyJodG1sIjoiIn0.XM6FzZ4J0WG-Y8nAFXKaCBBs8_osDVj7l_viFVHppSo",
            mailing_list: emails,
            urls_attachment: urls_to_send,
          },
        })
        .then((response) => {
          console.log("SE ENVIO EL EMAIL", response.data);
          // this.sendedEmailConfirmation()
          this.email = "";
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    async postUploadFile(path, signed_document) {
      var name_services = "/uploader/upload_file";
      var document_type = "solicitud_AP";
      if (path.nombre_catalogo_documento === "Autorizo") {
        document_type = this.name_catalog;
      }
      var data = {
        token_auth: this.token_auth_valid,
        file: path.url,
        document_name: document_type,
        business_acronym: "LMAYE",
        process: "policy_and_discount_auth_asigned",
        extension: "",
      };
      if (signed_document) {
        name_services = "uploader/upload_signed_file";
        data.url_for_sign = window.location.href;
          //"https://firma.autorizo.lineamagisterial.uno/?autorizo=88289b8a8d46c5d71f5dba65b2edb15b.pdf&plan_b=a6fa7c51fa76e1aa19c4f369d9002473.pdf&json_file=60c00157550cf251651f08cf3ccf014e.json";
      }
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await this.axios
        .post(this.apiCabsaCore + name_services, data, config)
        .then((response) => {
          if (response.data.errores == "") {
            this.url_for_download_pdf.push({
              url: response.data.url,
              document_name: path.nombre_catalogo_documento,
            });
          } else {
            this.is_not_document_signed = false;
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.is_not_document_signed = false;
          console.log("Error en postUpload: ", error);
        });
    },
    getPrivacynotice() {
      window.open("https://www.lineamagisterial.com/aviso.php");
    },
    createArraySendToEmail() {
      let result = [{ email: "" }];
      if (
        this.email != "" ||
        this.email != null ||
        this.email_associate == "" ||
        this.email_associate == null
      ) {
        return (result = [{ email: this.email }]);
      }
      if (
        this.email == "" ||
        this.email == null ||
        this.email_associate != "" ||
        this.email_associate != null
      ) {
        return (result = [{ email: this.email_associate }]);
      }
      if (
        this.email != "" ||
        this.email != null ||
        this.email_associate != "" ||
        this.email_associate != null
      ) {
        return (result = [
          { email: this.email },
          { email: this.email_associate },
        ]);
      }
      result;
    },
  },
};
</script>
<style scoped>
.activeClass {
  @apply bg-gray-600;
}
h1 {
  @apply text-xl font-semibold text-center text-dark m-8;
}
.headers {
  @apply w-full bg-darkBlue p-2 pl-5 text-white font-semibold text-base;
}
.subheading {
  @apply w-1/2 text-dark p-2;
}
.partner-col {
  @apply w-full p-2 ml-5;
}
.address-col {
  @apply w-full p-2 ml-5;
}
.benefits-col {
  @apply w-1/2 p-2;
}
.beneficiaries-col {
  @apply w-full p-2 ml-5;
}
.additionals-col {
  @apply w-full p-2 ml-5;
}
@screen md {
  h1 {
    @apply text-xl;
  }
  .headers {
    @apply p-3 pl-5 text-xl;
  }
  .subheading {
    @apply w-1/6 p-4 text-center;
  }
  .partner-col {
    @apply w-3/12 ml-0 pl-10;
  }
  .address-col {
    @apply w-3/12 ml-0 pl-10;
  }
  .benefits-col {
    @apply w-3/12 ml-0;
  }
  .beneficiaries-col {
    @apply w-2/12 ml-0 pl-10;
  }
  .additionals-col {
    @apply w-1/12 ml-0;
  }
}
hr {
  border-top: 2px solid #ff561e;
}
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #ff561e, #ff561e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
@media only screen and (max-width: 650px) {
  .hideInSmallScreens {
    display: none;
  }
}

@media only screen and (min-width: 650px) {
  .showInSmallScreens {
    display: none;
  }
}
</style>
