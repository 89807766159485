<template>
  <div class="bg-white">
    <div class="text-center">
      <div class="p-2 text-center">
        <p class="text-yellow-700 text-xs text-center">
          Versión: {{ version }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      version: process.env.VUE_APP_GIT_HASH,
      date: process.env.VUE_APP_GIT_DATE
    };
  },
  created: function() {
    console.log("La fecha de última versión es: " + this.date);
  }
};
</script>
