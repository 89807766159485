<template>
  <div>
    <div class="flex infline-flex  h-200 w-200">
      <img
        class="mt-10"
        style="display: block;
            margin-left: auto;
            margin-right: auto;"
        alt="Vue logo"
        src="@/assets/logo-horizontal.png"
      />
    </div>
    <div>
      <h1>
        Firma Digital Línea Magisterial
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  @apply text-3xl font-semibold text-center text-dark m-8;
}
</style>
